'use client'

import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'
import { cva } from 'class-variance-authority'
import { LucideIcon } from 'lucide-react'
import * as React from 'react'
import { cn } from 'src/utils'

interface HighlightContext {
  onMouseEnter: React.MouseEventHandler
  highlightStyles: React.CSSProperties
}

const VOID_FUNC = () => {}

const NavItem = (props: { className?: string; icon: LucideIcon }) => {
  return React.createElement(props.icon, {
    className: cn(
      'h-5 left scale-0 group-hover:scale-100 relative transition-all duration-300 min-[1000px]:absolute -left-5 top-1/2 -translate-y-1/2 group-hover:translate-x-2 group-hover:translate-x-2  group-data-[state=open]:translate-x-2 group-data-[state=open]:scale-100 group-hover:scale-100',
      props.className,
    ),
  })
}

const NavigationHighlightContext = React.createContext<HighlightContext>({
  onMouseEnter: VOID_FUNC,
  highlightStyles: { width: 0, opacity: 0 },
})

const NavigationMenu: React.FC<
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
> = ({ className, children, ...props }) => {
  const navRef = React.useRef<HTMLElement>(null)
  const [highlightStyles, setHighlightStyles] = React.useState<React.CSSProperties>({
    width: 36,
    opacity: 0,
  })

  const handleMouseEnter = React.useCallback((e: React.MouseEvent) => {
    const targetRect = e.currentTarget.getBoundingClientRect()
    if (!navRef.current) throw new Error('MainNav: missing nav ref')
    setHighlightStyles({
      transform: `translate3d(${Math.round(
        targetRect.x - navRef.current.getBoundingClientRect().x - 20,
      )}px, 0, 0)`,
      opacity: 1,
      width: Math.round(targetRect.width),
    })
  }, [])

  return (
    <NavigationHighlightContext.Provider
      value={{ onMouseEnter: handleMouseEnter, highlightStyles }}
    >
      <NavigationMenuPrimitive.Root
        ref={navRef}
        className={cn(
          'relative z-10 flex max-w-max flex-1 items-center justify-center',
          className,
        )}
        onMouseLeave={() => setHighlightStyles({ ...highlightStyles, opacity: 0 })}
        {...props}
      >
        {children}
        <NavigationMenuViewport />
      </NavigationMenuPrimitive.Root>
    </NavigationHighlightContext.Provider>
  )
}

NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => {
  return (
    <NavigationMenuPrimitive.List
      ref={ref}
      className={cn('group flex flex-1 list-none items-center space-x-', className)}
      {...props}
    />
  )
})
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName

const NavigationMenuItem = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Item>
>((props, ref) => {
  const { onMouseEnter } = React.useContext(NavigationHighlightContext)
  return (
    <NavigationMenuPrimitive.Item
      className="list-none flex align-stretch"
      ref={ref}
      {...props}
      onMouseEnter={onMouseEnter}
    />
  )
})

NavigationMenuItem.displayName = NavigationMenuPrimitive.Item.displayName
//md:data-[active]:bg-foreground/50
//focus:bg-foreground/25 focus:text-accent-foreground
const navigationMenuTriggerStyle = cva(
  'pointer relative group flex gap-2 relative z-10 group h-10 w-max items-center rounded-full pr-5 pl-3 py-2 transition-all duration-300 transition-colors hover:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 ',
)

const navigationTriggerLabel = cva(
  'pointer md:group-data-[state=open]:ml-2 md:group-data-[state=open]:mr-4 md:group-hover:ml-2 md:group-hover:mr-4 mr-6 transition-all duration-300 block',
)

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> & {
    icon?: LucideIcon
    iconClassName?: string
  }
>(({ className, children, icon, iconClassName, ...props }, ref) => {
  return (
    <NavigationMenuPrimitive.Trigger
      ref={ref}
      className={cn(navigationMenuTriggerStyle(), 'group', className)}
      {...props}
    >
      {!!icon && <NavItem icon={icon} className={cn(iconClassName)} />}
      <span className={cn(navigationTriggerLabel())}>{children}</span>
      {/* <ChevronDown
        className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
        aria-hidden="true"
      /> */}
    </NavigationMenuPrimitive.Trigger>
  )
})
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={cn(
      'left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto ',
      className,
    )}
    {...props}
  />
))
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName

const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Link>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link> & {
    icon?: LucideIcon
    iconClassName?: string
    labelClassName?: string
  }
>(({ className, children, icon, iconClassName, labelClassName, ...props }, ref) => {
  return (
    <NavigationMenuPrimitive.Link
      className={cn(
        navigationMenuTriggerStyle(),
        'flex gap-2 w-auto h-auto relative z-10',
        className,
      )}
      ref={ref}
      {...props}
    >
      {!!icon && <NavItem icon={icon} className={iconClassName} />}
      <span className={cn(navigationTriggerLabel(), labelClassName)}>{children}</span>
    </NavigationMenuPrimitive.Link>
  )
})

NavigationMenuLink.displayName = NavigationMenuPrimitive.Link.displayName

const NavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <div className={cn('absolute left-0 top-full flex justify-center')}>
    <NavigationMenuPrimitive.Viewport
      className={cn(
        'origin-top-center relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[var(--radix-navigation-menu-viewport-width)]',
        className,
      )}
      ref={ref}
      {...props}
    />
  </div>
))
NavigationMenuViewport.displayName = NavigationMenuPrimitive.Viewport.displayName

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className={cn(
      'top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in',
      className,
    )}
    {...props}
  >
    <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-border shadow-md" />
  </NavigationMenuPrimitive.Indicator>
))
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName

const NavigationMenuHighlight = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  const { highlightStyles } = React.useContext(NavigationHighlightContext)
  return (
    <div
      className={cn(
        'bg-primary/10 h-10 w-10 rounded-full absolute left-0 transition-all duration-300',
        className,
      )}
      {...props}
      style={highlightStyles}
      ref={ref}
    />
  )
})

NavigationMenuHighlight.displayName = 'NavigationMenuIndicator'

export {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuHighlight,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
}
