'use client'

import { NAV_ITEMS } from '../../constants/nav.constants'
import { Drawer, DrawerContent, DrawerTrigger } from '../ui/drawer'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
} from '../ui/navigation-menu'
import { Menu, Sparkles } from 'lucide-react'
import { FC } from 'react'
import { NavItems } from '~/lib/load-nav'

const MobileNav: FC<Omit<NavItems, 'integrations'>> = (props) => {
  return (
    <Drawer>
      <DrawerTrigger className="min-[1000px]:hidden">
        <Menu />
      </DrawerTrigger>
      <DrawerContent className="px-6 pb-6 min-[1000px]:hidden">
        <NavigationMenu className="grid gap-3">
          <NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuLink icon={Sparkles} iconClassName="scale-100">
                Product
              </NavigationMenuLink>
            </NavigationMenuItem>
          </NavigationMenuItem>

          <ul className="ml-8">
            {props.products.map((p, key) => (
              <NavigationMenuLink
                key={key}
                href={
                  p.comingSoon ? '/book-demo' : `/products/${p.slug.current.toString()}`
                }
                className="block select-none space-y-1"
              >
                <p className="text-sm font-medium leading-none">
                  {p.name}{' '}
                  {p.comingSoon ? (
                    <span className="bg-green-300 inline-block rounded-full py-1 px-1 font-mono text-[11px]">
                      Coming Soon
                    </span>
                  ) : (
                    ''
                  )}
                </p>
                <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                  {p.description}
                </p>
              </NavigationMenuLink>
            ))}
          </ul>

          {NAV_ITEMS.map((item, key) => {
            return (
              <NavigationMenuItem key={key}>
                <NavigationMenuLink
                  href={item.href}
                  icon={item.icon}
                  iconClassName="scale-100"
                >
                  {item.label}
                </NavigationMenuLink>
              </NavigationMenuItem>
            )
          })}
        </NavigationMenu>
      </DrawerContent>
    </Drawer>
  )
}

export default MobileNav
