'use client'

import { Sparkles } from 'lucide-react'
import { ComponentPropsWithoutRef, FC } from 'react'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuHighlight,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
} from '~/components/ui/navigation-menu'
import { NAV_ITEMS } from '~/constants/nav.constants'
import { NavItems } from '~/lib/load-nav'

const DesktopNav: FC<
  ComponentPropsWithoutRef<typeof NavigationMenu> & Omit<NavItems, 'integrations'>
> = ({ products, ...props }) => {
  return (
    <NavigationMenu {...props} className="xl:ml-10 min-[1000px]:flex hidden">
      <menu className="flex">
        <NavigationMenuItem>
          <NavigationMenuTrigger icon={Sparkles}>Product</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[600px] md:grid-cols-2 lg:w-[700px] ">
              {products.map((p, key) => (
                <li key={key}>
                  <NavigationMenuLink
                    href={
                      p.comingSoon
                        ? '/book-demo'
                        : `/products/${p.slug.current.toString()}`
                    }
                    className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-secondary-tan hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                  >
                    <p className="text-sm font-medium leading-none flex items-center gap-1">
                      {p.name}{' '}
                      {p.comingSoon ? (
                        <span className="bg-green-300 inline-block rounded-full py-1 px-1 font-mono text-[11px]">
                          Coming Soon
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                    <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                      {p.description}
                    </p>
                  </NavigationMenuLink>
                </li>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>

        {NAV_ITEMS.map((item, key) => {
          return (
            <NavigationMenuItem key={key}>
              <NavigationMenuLink href={item.href} icon={item.icon}>
                {item.label}
              </NavigationMenuLink>
            </NavigationMenuItem>
          )
        })}
      </menu>
      <NavigationMenuHighlight />
    </NavigationMenu>
  )
}

export default DesktopNav
