'use client'

import { Button } from './ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from './ui/form'
import { Input } from './ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { usePostHog } from 'posthog-js/react'
import { useForm } from 'react-hook-form'
import { subscribeToNewsletter } from '~/app/actions'
import { NewsletterFormSchema, newsletterFormSchema } from '~/schemas/forms'

export function NewsletterForm() {
  const event = usePostHog()
  const form = useForm<NewsletterFormSchema>({
    resolver: zodResolver(newsletterFormSchema),
    defaultValues: {
      email: '',
    },
  })

  const submit = async (values: NewsletterFormSchema) => {
    event.capture('Subscribed to Newsletter')
    try {
      await subscribeToNewsletter({ email: values.email })
      form.reset()
    } catch (error) {
      form.setError('email', {
        type: 'manual',
        message:
          "we're unable to subscribe you to our newsletter. Please try again later.",
      })
      event.capture('error submitting newsletter form', values)
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(submit)}
        className="flex items-start space-x-2 mb-2"
      >
        <label className="bg-white block p-4 border-white rounded-md focus-within:outline-none focus-within:ring-4 focus-within:ring-ring/50 focus-within:border-ring">
          <p className="flex-1 w-100 mb-3 text-foreground/70 text-sm">
            Stay up-to-date with the updates from Happitu, trends in customer service, and
            latest releases from Next in Queue.
          </p>
          <div className="flex">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className="w-full p-0 text-2xl border-0 focus-visible:ring-0 focus-visible:ring-ring/0 focus-visible:border-0"
                      placeholder="Your email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="my-1 text-xs" />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant={form.formState.isSubmitSuccessful ? 'success' : 'primary'}
              disabled={!form.formState.isValid && !form.formState.isSubmitSuccessful}
              loading={form.formState.isSubmitting}
              aria-label="Subscribe to newsletter"
            >
              {form.formState.isSubmitSuccessful ? 'Subscribed!' : 'Subscribe'}
            </Button>
          </div>
        </label>
      </form>
    </Form>
  )
}
