import { Blocks, GraduationCap, IceCream2 } from 'lucide-react'

export const NAV_ITEMS = [
  // {
  //   label: 'Mission',
  //   icon: Zap,
  //   href: '/mission',
  // },
  {
    label: 'Integrations',
    icon: Blocks,
    href: '/integrations',
  },
  {
    label: 'Pricing',
    icon: IceCream2,
    href: '/pricing',
  },
  {
    label: 'Learn',
    icon: GraduationCap,
    href: '/learn',
  },
]
